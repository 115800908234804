@import "../../../assets/scss/index";

.tools-icons {
  padding: 0 15px;

  &__item {
    width: 50px;
    height: 50px;
    background: transparent;
    border: none;
    cursor: pointer;
    @include media(">tablet") {
      width: 78px;
      height: 78px;
    }

    &:hover {
      background-color: $btn-hover;
    }
  }

  img {
    width: 100%;
  }
}

.elRotationMenu, .elZoomMenu, .elColorMenu {
  display: none;
}

.activeFontSize {
  display: none;
}

.active,
.activeColor {
  position: relative;

  @include media("<616px") {
    padding-bottom: 25px;
  }

  .elRotationMenu, .elZoomMenu {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 38px;
    transform: translateY(100%);
    left: -14px;
    width: 72px;
    @include media(">616px") {
      width: 86px;
      bottom: -8px;
    }

    img {
      max-width: 22px;
      cursor: pointer;
    }
  }

  .elColorMenu, .activeFontSize {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 35px;
    transform: translateY(100%);
    left: -4px;
    width: 72px;
    @include media(">616px") {
      left: -14px;
      width: 86px;
      bottom: -8px;
    }


    img {
      max-width: 22px;
      cursor: pointer;
    }
  }
}


.active .activeFontSize {
  @include media(">616px") {
    bottom: 22px;
  }
}

.activeFontSizeInput {
  width: 45px;
  text-align: center;
  padding: 2px !important;
}

.elColorMenu {
  display: none;
}

.activeColor {
  position: static;

  .elColorMenu {
    left: 0;
    width: 100%;
    flex-wrap: wrap;
    display: flex;
  }
}



.imgMenu, .textMenu {
  background-color: $main-bg;
  padding: 10px;
  overflow: auto;
  align-items: flex-start;
  max-width: 320px;
  margin: 0 auto;
  @include media(">616px") {
    text-align: center;
    max-width: 100%;
    //top: 86px;
    //max-height: 37vh;
    padding: 40px 10px;
  }

  > div {
    display: inline-flex;
    position: relative;
    border-bottom: none;

    &.designer-right-panel-item {
      width: 100%;
    }
  }

  img {
    margin-bottom: 5px;
    width: 48px;
    @include media(">616px") {
      width: 56px;
    }
  }

  button {
    cursor: pointer;
    color: #333333;
    font-size: 12px;
    font-weight: 400;
    border: none;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 0;
    max-width: 48px;
    @include media(">616px") {
      max-width: 56px;
      margin-right: 33px;
      margin-bottom: 0;
      font-size: 14px;
    }
  }
}

.textMenu_lineSpace {
  button {
    margin-bottom: 45px;
  }
}


.imgMenu {
  // height: 110px;
  @include media(">616px") {
    height: auto;
  }
}

.textMenu {
  width: 100vw;
  height: 180px;
  max-width: 350px;
  margin: 0 auto;
  padding: 10px;
  @include media(">725px") {
    max-width: 100vw
  }
  @include media(">wide") {
    max-width: 100%;
    height: 510px;
  }

  button {
    margin-right: 10px;
    margin-left: 11px;
    max-width: 51px;
    margin-bottom: 25px;
    font-size: 12px;
    overflow: visible;
    @include media(">725px") {
      font-size: 14px;
      max-width: 56px;
      margin-left: 0;
      margin-right: 28px;
      margin-bottom: 25px;
    }
  }

  img {
    width: 48px;
    @include media(">725px") {
      width: 56px;
    }
  }

  @include media(">725px") {
    padding: 40px 10px;
  }

}


.textMenu_elementsList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.textMenu_elementAdd {
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  display: flex;
  margin-top: 15px;

  img {
    max-width: 46px
  }

  @include media("<616px") {
    justify-content: center;
  }
}

.textMenu_listItem {
  width: 90%;
  max-width: 450px;
  margin: 15px auto;
  display: flex;
  align-items: center;

  @include media("<616px") {
    margin: 9px auto;
  }

  textarea {
    border: 1px solid #ADADAD;
    background-color: transparent;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 8px;
    max-width: 450px;
    width: 100%;
    margin-right: 15px;
  }

  img {
    width: 100%;
    max-width: 30px;
  }
}

.elColorMenu__btn {
  margin: 6px 5px;
  padding: 20px;
  border: none;
  border-radius: 5px;

  @include media(">tablet") {
    margin: 11px 10px;
    padding: 40px;
  }

}


.tools__btn button {
  //margin-left: 5px;
  //cursor: pointer;
  //width: 100px;
  //text-align: center;
  //border-radius: 2px;
  //border: 0;
  //height: 30px;
}

.tools__btn {
  padding: 0 20px;
  margin-top: 10px;
  text-align: right;
  //button {
  //  transition: background-color .3s ease-in-out;
  //  margin-left: 0;
  //  background-color: $primary;
  //  color: $white;
  //}
}


.uploader {
  &__title {
    font-weight: 600;
    font-size: 18px;
    padding: 17px 15px 10px 22px;
    color: $primary;
    display: flex;
  }

  &__files {
    border-top: $border-col;
    padding: 5px;
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    overflow: auto;
  }


  &__img {
    margin-right: 10px;
    margin-bottom: 7px;
    cursor: pointer;
    width: 100%;
    max-width: 86px;
    border: 1px solid #f2f2f2;
    border-radius: 2px;

    &:hover {
      box-shadow: 0 1px 4px 0 rgba($black, .5);
    }

    &_round {
      margin-right: 7px;
      border-radius: 100px;
      max-width: 60px;
    }
  }

  &__icon {
    font-size: 19px;
    margin-right: 10px;
    fill: $primary;
  }

  &__text {
    font-size: 15px;
  }
}

.designer-tools-backgrounds-list {
  text-align: center;
  padding: 10px;
}


.designer-tools-backgrounds-list img {
  cursor: pointer;
  margin: 0 5px;
}

.element {
  color: $primary;
  margin-top: -1px;
  padding-left: 34px;
  transition: background-color .3s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;

  &__arrowsWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -25px;
    padding-right: 10px;
  }

  &__arrowDown,
  &__arrowUp {
    display: block;
    font-size: 15px;
    padding: 0 1px;
  }

  &__arrowDown {

  }

  &__arrowUp {
  }

  .element__icon--text {
    img {
      display: none;

      &:first-child {
        display: block;
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    width: 90%;
    padding: 10px 10px 10px 0;
    border-bottom: 1px solid $border-col;
    min-height: 55px;
  }

  &:hover {
    background-color: rgba($active-col, .45);
  }

  &--active {
    background-color: $active-col;
    color: $white;

    .element__inner {
      border: none;
    }

    .element__icon--text {
      img {
        &:first-child {
          display: none;
        }

        &:last-child {
          display: block;
        }
      }
    }

    .element__icon {
      border-color: $white;
    }
  }

  &:last-child {
    .element__inner {
      border: none;
    }
  }

  &__img {
    padding-right: 14px;
    width: 53px;

    img {
      //width: 100%;
      max-width: 40px;
      max-height: 40px;
      display: block;
    }
  }

  &__title {
    //max-width: 143px;
    font-size: 12px;
    padding-top: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-letter {
      text-transform: uppercase;
    }

    &--description {
      font-size: 12px;
      width: 100%;
      padding-top: 2px;
      display: block;
      font-weight: 100;
    }
  }

  &__icon {
    width: 100%;
    max-width: 50px;
    max-height: 50px;
    box-sizing: content-box;
    font-size: 35px;
    overflow: hidden;
    border: 1px solid $primary;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 17px;

    &--text {
      max-width: 37px;
      max-height: 37px;
      align-self: stretch;
    }

    img {
      width: 100%;
      max-width: 24px;
      max-height: 24px;
    }
  }
}


.dropdown {
  padding: 6px 16px;
  background-color: $main-bg;
  display: flex;
  align-items: center;
  margin-top: -1px;
  cursor: pointer;
  transition: background-color .3s ease-in-out;

  &:hover {
    background-color: #e6e6e6;
  }

  &--active {
    .dropdown__icon {
      transform: rotate(180deg);
    }
  }

  &__img {
    padding-right: 13px;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;

    img {
      width: 100%;
      display: block;
      max-height: 38px;
    }
  }

  &__title {
    font-size: 12px;
    font-weight: 600;
    color: $black;
    flex: 1 1 auto;
  }
}


.shapes {
  padding: 11px 20px 41px 20px;
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  margin: 0 auto;
  position: relative;
  @include media(">616px") {
    padding: 20px;
  }

  .elZoomMenu {
    bottom: 0;
    left: -10px;
    width: 60px;
    @include media(">616px") {
      width: 74px;
    }
  }

  button {
    max-height: 56px;
    width: 100%;
    max-width: 35px;
    background-color: transparent;
    border: none;
    padding: 0;
    margin-bottom: 10px;

    @include media("<616px") {
      img {
        width: 100%;
      }
    }
    @include media(">616px") {
      max-width: 40px;
      margin-right: 17px;
      img {
        width: 100%;
      }
    }

    &:hover {
      background-color: transparent;
      box-shadow: none;
    }
  }

  &__title {
    margin-left: 10px;
  }

}

.textMenu {
  .designer-right-panel-item {
    border: none;
  }

  .designer-right-panel-item__titleFlex {
    //font-size: 20px;
  }

  .item_shadow {
    width: 100%;
  }
}

.textMenu {
  position: relative;
}

div.textMenu__closeBtn {
  position: absolute;
  top: 5px;
  right: 6px;
  z-index: 1;
  display: block;
  text-align: right;
  max-width: 616px;
  margin: 0 auto;
  @include media(">616px") {
    top: 10px;
    right: 20px;
  }

  img {
    width: 100%;
    max-width: 20px;
  }
}

.fontAlightText {

  @include media("<=616px") {
    width: 180px;
    button{
      width: 100%;
      max-width: 250px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      img{
        margin: 0 2px;
        margin-bottom: 5px;
      }
    }
  }
}

.textMenu input::-webkit-outer-spin-button, .elZoomMenu input::-webkit-outer-spin-button,
.textMenu input::-webkit-inner-spin-button, .elZoomMenu input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
