@import "../../assets/scss/index";

.designer-menu-wrap {
  background-color: $main-bg;
  border-bottom: 1px solid $border-col;
  box-shadow: 2px 0 4px 1px rgba(48, 58, 56, .1);
}

.designer-subMenu {
  background-color: $white;
  max-height: 60px;
  overflow: hidden;
}

.closeEditorBtn {
  cursor: pointer;
  margin-left: 7px;
  @include media(">=tablet") {
    margin-left: 15px;
  }
}

.designer-subMenuWrap {
  max-width: 1250px;
  margin: 0 auto;
  padding: 0 14px;
}

.designer-subMenu__history {

}

.designer-subMenu__historyBtnImg {
  max-width: 16px;
  display: block;
  margin: 0 auto 5px auto;
}

.designer-subMenu__historyBtn {
  background-color: transparent;
  border: none;
  min-width: 50px;
  height: 50px;
  padding: 5px;
  cursor: pointer;
  font-family: $font-primary;
  margin: 5px 5px 5px 0;

  & + .designer-subMenu__historyBtn {
    border-left: 1px solid rgba(48, 58, 56, .1);
  }
}

.primary-button {
  font-family: $font-primary;
  font-weight: 500;
  border: 1px solid $primary;
  border-radius: 2px;
  background-color: $primary;
  color: $white;
  font-size: 12px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: background-color .3s, color .3s;
  line-height: 15px;
  text-align: center;
  padding: 10px;
  @include media(">tablet") {
    padding: 15px 64px;
  }
}

.designer-menu-item {
  .btn-dark {
    border-radius: 100px;
    font-size: 16px;
  }
}

.designer-menu-item_preview .secondary-button {
  font-family: $font-primary;
  font-weight: 600;
  border: 1px solid #F2F4F3;
  background-color: #F2F4F3;
  color: #222C28;
  font-size: 13px;
  letter-spacing: 1px;
  cursor: pointer;
  transition: background-color .3s, color .3s;
  line-height: 15px;
  text-align: center;
  padding: 5px;
  margin-right: 5px;
  border-radius: 100px;
  @include media(">tablet") {
    padding: 15px 25px;
    font-size: 16px;
    margin-right: 15px;
  }
}

.designer-menu-item_preview .secondary-button:hover {
  background-color: $primary;
  border-color: $primary;
  color: $white;
}

.primary-button:hover {
  background-color: $white;
  border-color: $primary;
  color: $primary;
}

.designer-menu-logo {
  width: 100%;
  text-align: center;
  max-width: 33.33%;
  display: none;
  @include media(">tablet") {
    display: block;
  }

  img {
    width: 100%;
    max-width: 176px;
  }
}

.designer-menu {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 11px 10px;
  @include media(">tablet") {
    padding: 16px;
    min-height: 80px;
  }

  .designer-menu-item {
    position: relative;
    color: #212121;

    &.right {
      width: 100%;
      max-width: 33.33%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include media("<=tablet") {
        max-width: 100%
      }
    }

    &.left {
      width: 100%;
      max-width: 33.33%;
      @include media("<=tablet") {
        max-width: 100%
      }
      display: flex;
      align-items: center;
    }

    .designer-menu-submenu {
      position: absolute;
      background-color: $white;
      top: 35px;
      left: 0;
      box-shadow: 0 1px 4px 0 rgba($black, .5);
      width: 150px;
      z-index: 3;
      padding-top: 10px;
      padding-bottom: 10px;

      span {
        width: 100%;
        display: block;
        box-sizing: border-box;
        font-size: 12px;
        line-height: 30px;
        border: 0;
        margin: 0;
        background-color: transparent;
        padding: 0 20px;
        text-align: left;
        //@include media(">=tablet") {
        //  padding: 0 20px;
        //}
        &.disabled {
          opacity: 0.4;
        }

        &:hover {
          background-color: #dbf2ff;
          cursor: pointer;
        }
      }
    }
  }

  .canvas-status {
    display: inline-block;
    font-size: 14px;
    text-align: right;
  }

  .menu-item {
    //display: inline-block;
    //border: 0;
    //background-color: transparent;
    //padding: 3px 5px;
    //cursor: pointer;
    //font-family: $font-primary;
    //color: $black;
    //font-size: 12px;
    //line-height: 15px;
    //letter-spacing: 1px;
    //transition: background-color .3s, color .3s;
    @include media(">tablet") {
      padding: 7px 10px;
    }

    //&.active {
    //  box-shadow: 0 1px 4px 0 rgba($black, .5);
    //}

    &:hover {
      //background-color: $primary;
      //border-color: $primary;
      //color: $white;
      //border-radius: 2px;
    }
  }

  .designer-menu-separator {
    background-color: $border-col;
    height: 1px;
    margin: 0 10px;
  }

  .close-menu-item {
    background-color: #f1b6b1;
    margin-left: 15px
  }

  .done-menu-item {
    background-color: #d2de96
  }
}

.designer-menu-item__rotation {
  img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    cursor: pointer;
    @include media(">tablet") {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }

  &:after {
    content: "";
    display: none;
    height: 25px;
    width: 2px;
    background-color: #333333;
    margin-left: 5px;
    margin-right: 15px;
    @include media(">tablet") {
      height: 35px;
      margin-right: 17px;
      display: inline-block;
    }
  }
}

.designer-menu-item__zoom {
  display: none;
  margin-right: 10px;
  @include media(">tablet") {
    display: block;
  }

  img {
    width: 22px;
    height: 22px;
    cursor: pointer;
    @include media(">tablet") {
      width: 30px;
      height: 30px;
    }

    &:first-child {
      margin-right: 10px;
    }
  }
}


.designer-menu-item .btn-dark {
  &.generateprintfile {
    @include media("<=tablet") {
      font-size: 13px;
      padding: 7px 12px;
    }
  }
}