@import "../../assets/scss/index";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

.designer-right-panel {
  width: 100%;
  border-left: 1px solid $border-col;
  border-top: 1px solid $border-col;
  position: relative;
  height: 100%;
  @include media("<=wide") {
    display: flex;
    //justify-content: center;
  }
}

.properties-column {
  max-width: 255px;
  width: 100%;
  display: flex;
  box-shadow: 0 1px 2px 0 rgba(48, 58, 56, 0.1), 0 8px 16px 0 rgba(40, 52, 49, 0.1);
  //padding-bottom: 280px;
  @include media(">wide") {
    margin-right: 14px;
    margin-bottom: 14px;
  }
  @include media("<=wide") {
    padding-bottom: 0;
    max-width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;

    z-index: 100;
    min-height: 74px;
    width: 100%;
    background-color: $white;
    box-shadow: 1px -4px 6px 0px rgba($black, .10);
    margin-top: 170px;
    overflow-x: scroll;
    &.active {
      //height: 35vh;
      overflow: hidden;
      margin-top: 0;
      position: relative;
      min-height: calc(100vh - 60vh);
    }
  }
}


.designer-right-panel-item__column {
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 auto;
}

.colorSwitchList {
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 10px;
  display: flex;
  //@include media("<=wide") {
  //  padding-top: 5px;
  //  max-width: 100vw;
  //  flex-wrap: initial;
  //  overflow-x: scroll;
  //  .colorSwitchBtn {
  //    width: 45px;
  //    height: 45px;
  //    border-radius: 50%;
  //    margin: 10px;
  //    padding: 20px;
  //    border: 1px solid $border-col;
  //
  //    &Active {
  //      box-shadow: 0 0 0 3px rgba(0, 123, 255, .15), 0 3px 15px rgba(0, 123, 255, .2), 0 2px 5px rgba(0, 0, 0, .1);
  //      border-color: #006fe6;
  //    }
  //  }
  //}
  .colorSwitchBtn {
    width: 25px;
    height: 25px;
    margin: 5px;
  }


}

.designer-right-panel-item__color {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}


.designer-right-panel-item__titleFlex {
  .designer-right-panel-item__titleFlex {
    padding: 0;
  }

  padding: 16px 19px;
  display: flex;
  min-height: 16px;
  align-items: center;
  min-width: 70px;
  @include media("<=wide") {
    text-align: center;
  }

  .designer-right-panel-item__trash {
    display: none;
    @include media("<=wide") {
      display: block;
    }
  }

  .text {
    font-size: 12px;
    font-weight: 600;
  }

  .svg-inline--fa, img {
    width: 18px;
    height: 18px;
    margin-right: 13px;
  }

  @include media("<=wide") {
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding: 15px 5px;
    .svg-inline--fa, img {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}

.dropdownContent {
  display: none;
  padding: 5px 10px;
  width: 100%;
  margin: 0 -5px;
  //background-color: $white;

  &Btn {
    display: none;
    @include media("<wide") {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  &List {
    display: block;
    @include media("<wide") {
      display: block;
      width: 100%;
      overflow: auto;
      max-height: 22vh;
      //background-color: $white;
      margin-bottom: 10px;
    }
  }

  &Block {
    @include media("<=wide") {
      width: 100%;
      justify-content: space-around;
      display: flex;
      margin-top: 30px;
      margin-bottom: 30px;
    }

    &Btn {
      @include media("<=wide") {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: white;
        box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.3);
        border: none;
      }
    }
  }

  @include media("<=wide") {
    padding: 0 5px;
    overflow-y: scroll;
  }
}

.designer-right-panel-item.active .dropdownContent {
  display: block;
}

.designer-right-panel-item.active {
  @include media("<wide") {
    //height: 40vh;
  }
}

.designer-rotate-icon {
  @include media(">wide") {
    display: none;
  }
}

.designer-rotate-btns {
  //display: none;
  display: flex;
  @include media(">wide") {
    display: block;
  }
}


.designer-right-panel-items {
  @include media("<=wide") {
    display: none;
  }
}

.designer-right-panel-positions {
  display: flex;
  line-height: 1.5;
  flex-wrap: wrap;

  input {
    width: 100%;
    max-width: 90px;
    margin-left: 15px;
  }
}

.colorSwitchList__recently {
  font-size: 16px;
  line-height: 2;
  @include media("<=wide") {
    text-align: center;
    max-width: 100vw;
    .colorSwitchList {
      justify-content: center;
    }
  }
}

.custom-checkbox {
  margin-right: 10px;
}

.designer-right-panel-item__titleFlex {
  //margin-right: 10px;
  font-size: 12px !important;
  font-weight: 600;
}

.colorSwitchBtn {
  width: 20px;
  height: 20px;
  border: none;
  margin: 2px;
  padding: 0;
  cursor: pointer;

  &:hover {
    border: 2px solid #fda039;
  }
}

.designer-right-panel {
  background-color: $white;
  background-repeat: no-repeat;
  background-position: center 100px;

  @include media("<=wide") {
    height: auto; //!important;
    width: 100%;
    background-color: $white;
    max-width: 100%;
    border: none;
    &.designer-right-panel-minWidth {
      //min-width: 775px;

      &.active {
        width: 100vw;
      }
    }
  }
  @include media(">wide") {
    padding-bottom: 315px;
    overflow: auto;
  }
}

.designer-right-panel-placeholder {
  text-align: center;
  padding: 10px 20px;
  color: #bbbbbb;
  position: relative;
  line-height: 1.5;

  @include media(">wide") {
    top: 50%;
    transform: translateY(-100%);
  }
}

.designer-right-panel-window {
  width: 100%;
  background-color: $white;
  height: auto !important;
  overflow: auto;
  @include media("<=wide") {
    height: auto !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  @include media(">wide") {
    flex-direction: column;
    height: 100% !important;
  }
}

.designer-right-panel-item {
  font-weight: bold;
  border-bottom: 1px solid rgba($border-col, .7);
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  //max-width: 120px;
  width: 100%;
  @include media(">wide") {
    padding-right: 23px;
    overflow: hidden;
    max-width: 100%;
    position: relative;
    font-size: 16px;
    width: auto;
    justify-content: space-between;
  }

  &:hover {
    @include media(">wide") {
      //background: $btn-hover;
    }
  }
}

.designer-right-panel-btn {
  cursor: pointer;
  margin-left: 5px;
  width: 30px;
  border: 1px solid rgba(#A7AAA8, 1);
  background-color: $white;
  height: 30px;
  padding: 6px;
  border-radius: .35rem;

  .svg-inline--fa {
    height: 20px;
    padding-right: 0;
  }

  &.designer-right-panel-btn_size {
    transition: transform .1s;

    &:hover {
      transform: scale(1.05);
    }

    padding: 0;
    //border: none;
    //border: none;

    img {
      width: 100%;
    }
  }
}

.designer-right-panel-colorBox {
  width: 100%;
  max-width: 60px;
  height: 36px;
  box-sizing: border-box;
  position: relative;
  border: 3px solid $white;
  border-radius: .35rem;

  &:after {
    content: '';
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-radius: .35rem;
    position: absolute;
    left: -3px;
    top: -3px;
    border: 1px solid #becad6;
    display: block;
  }

}

.designer-right-panel-inputNumber {
  padding-left: 10px;
  margin-left: 8px;

  input {
    //border: 1px solid rgba(#A7AAA8, 1);
    width: 60px;
    //height: 30px;
    font-size: 15px;
    //color: $primary;
    //padding-left: 6px;
    font-family: $font-secondary;
  }

  @include media("<=wide") {
    padding-left: 7px;
    margin-bottom: 10px;
    input {
      width: 45px;
    }
  }
}

.selected-font {
  display: none;
  @include media(">wide") {
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    flex: 1 1 100%;
    width: 100%;
    //text-align: center;
    padding-left: 10px;
    font-weight: 600;
    display: block;
  }
}

.font-family-selector {
  max-height: 123px;
  overflow: auto;
  @include media("<wide") {
    overflow: initial;
    max-height: 100%;
    div {
      margin-top: 10px;
      margin-bottom: 5px;
      text-align: center;
      padding: 0 15px;
    }
  }


  .active-font {
    background-color: rgba(#000000, .75);
    color: $white;
    font-size: 18px;
    padding: 5px 15px;
  }

  .font {
    font-size: 16px;
    margin-bottom: 5px;

    &:hover {
      background-color: rgba(#000000, .35);
      fill: $white;
      @include media("<wide") {
        background-color: transparent;
      }
    }
  }
}

.designer-right-panel-item__fontType {
  .designer-right-panel-item__titleFlex {
    //padding-right: 0;
    width: 100%;
    //flex-direction: row;
  }
}

.designer-right-panel-item__fontStyle {
  @include media("<=wide") {
    display: none;
  }
}

.designer-right-panel-item__fontTransform {
  flex-direction: column;
  overflow: visible;

  .designer-right-panel-item__titleFlex {
    width: 100%;
  }

  .designer-right-panel-inputNumber {
    width: 100%;
    margin-bottom: 15px;
  }

  @include media("<=wide") {
    display: none;
  }
}

.designer-right-panel-item__deleteBtn,
.designer-right-panel-item_justification,
.designer-right-panel-item_changeSize {
  .designer-right-panel-item__titleFlex {
    padding-left: 19px;
  }
}


.designer-right-panel-item__deleteBtn {
  padding-right: 0;
  transition: background-color .3s ease-in-out;

  &:hover {
    background-color: rgba($warning-col, 0.35);
  }

  .designer-right-panel-item__titleFlex {
    width: 100%;
    justify-content: center;
  }
}

.designer-right-panel-item--admin {
  @include media("<=wide") {
    display: none;
  }
}

.designer-right-panel-item_positions {
  @include media("<=wide") {
    display: none;
  }
  flex-direction: column;

  .designer-right-panel-item__titleFlex {
    width: 100%;
  }

  .designer-right-panel-inputNumber_title {
    display: flex;
    align-items: center;
    padding-left: 5px;
    margin-bottom: 5px;
    justify-content: space-between;
    width: 100%;
    max-width: 140px;
  }
}


.designer-right-panel-item_justification {
  display: flex;
  @include media("<=wide") {
    .designer-right-panel-item__titleFlex {
      padding: 5px;
    }
    flex-direction: column;
    margin: 0 10px;
    .designer-right-panel-btn:nth-child(1) {
      margin-left: 0;
    }
  }
}

.designer-right-panel-item_changeSize {
  display: none;

  button {
    border: 1px solid rgba(#A7AAA8, 1);
    background-color: $white;
    height: 30px;
    padding: 6px;
  }

  @include media(">wide") {
    display: flex;
  }
}

.designer-right-panel-item_changeSize {
  justify-content: flex-start;
}

.designer-right-panel-item_rotate.active .dropdownContent {
  display: flex;
  @include media("<=wide") {
    padding-top: 37px;
  }
}

.designer-rotate-close {
  display: none;
}

.designer-right-panel-item_rotate {
  padding-right: 0;

  .designer-rotate-btns {
    display: flex;

    button {
      height: 35px;
      padding-top: 8px;
    }

    @include media("<=wide") {
      flex-direction: row;
      .designer-rotate-close {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        border: 1px solid rgba($border-col, 1);
        padding: 3px 5px;
        font-family: $font-primary;
      }
    }
  }

  .text {
    padding-right: 28px;
  }

  @include media("<=wide") {
    .text {
      padding-right: 0;
    }
    //display: none;
    //max-width: 100%;
    width: 100%;
    // .svg-inline--fa, img {

    // }
    .designer-rotate-btns {
      width: 100%;
      display: none;

      .svg-inline--fa, img {
        display: block;
        margin: 0 auto;
      }

      .designer-right-panel-btn {
        &:first-child {
          order: 2;
        }

        width: 50%;
        background-color: transparent;
        border: none;
        font-size: 16px;
      }
    }
  }

  .designer-right-panel-item__titleFlex {
    width: 100%;
  }

  .designer-right-panel-inputNumber {
    padding-left: 0;
    order: 1;
  }


}

.designer-right-panel-item_letterSpacing {

  @include media("<wide") {
    max-width: 145px;
    margin: 0 5px;
    .designer-right-panel-inputNumber input {
      display: none;
    }
    .designer-right-panel-inputNumber {
      margin-left: 0;
      margin-top: -15px;
    }
  }
  flex-wrap: wrap;

  .designer-right-panel-item__titleFlex {
    padding-top: 14px;
    padding-bottom: 14px;
    flex-wrap: wrap;
    width: 100%;
    @include media("<wide") {
      padding: 15px 10px 0 10px;
    }

    img {
      display: none;
    }
  }

  .designer-right-panel-inputNumber {
    display: flex;
    margin-bottom: 10px;
  }
}

.designer-right-panel-item_letterSpacingRange {
  width: 100%;
  max-width: 110px;
  margin-right: 43px;
  @include media("<wide") {
    margin-right: 0;
  }
}

.designer-right-panel-item__checkboxes {
  display: flex;
}


.designer-right-panel-item_fontSize {
  .designer-right-panel-inputNumber {
    display: block;
  }

  @include media("<wide") {
    .designer-right-panel-inputNumber {
      display: none;
    }
    padding-right: 5px;
    width: auto;
  }

  .designer-right-panel-item__titleFlex {
    @include media("<wide") {
      padding-right: 0;
      width: auto;
    }
  }
}

.propMask {
  padding: 15px 10px;

  .form-control {
    margin-top: 5px;
    max-width: 54px;
    margin-right: 4px;
    padding: 3px;
  }

  b {
    font-weight: 600;
    display: block;
    margin-bottom: 5px;
  }
}

.designer-right-panel-item__colorFont {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 616px;
  margin:0 auto;

  @include media("<616px") {
    margin-top: 20px;
  }

  .text {
    font-size: 18px;
  }

  .designer-right-panel-item__titleFlex {
    justify-content: flex-start;
    flex-direction: row;
  }

  .arrow {
    transform: rotate(-180deg);
    margin: 0 0 0 15px;
    border: 1px solid #000000;
    border-radius: 100px;

    &.active {
      transform: rotate(-90deg)
    }
  }
}

.designer-right-panel-item__FontStroke {
  flex-direction: row;
}

.designer-right-panel-item_shadow {
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  padding: 15px 0;
  max-width: 616px;
}

.adminPanel {
  display: none;
  @include media(">wide"){
    position: absolute;
    right: 0;
    top: 0;
    height: 90vh;
    z-index: 2;
    display: block;
  }
}

.NewEditor-custom-button{
  margin: 15px auto 0;
    display: block;
    width: max-content;
}
.NewEditor-custom-button button {
  margin: 0 5px;
  // border: none;
  // font-weight: 600;
  // padding: 16px;
  // font-size: 16px;
  // color: #fff;
  // background: #000;
  // border-radius: 100px;

}
.firebox-label-cls {
  font-size: 14px;
  line-height: 1.8;
}
.firebox-genius-heading {
  font-weight: 800;
  color: #000;
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 55px;
  font-family: sans-serif;
}
.firebox-genius-heading img {
  width: 20px!important;
  margin-bottom: 0;
}
.firebox-genius-heading.firebox-genius-heading-mobile {
  display: none;
}
.firebox-genius-heading-desktop{
  text-align: left;
  padding-left: 45px;
}
.meharCommon-submenu-cls span.Tools_tools__itemText__Vyhd- img {
  margin: 0 auto 5px;
  width: 48px;
}
.meharCommon-submenu-cls {
  display: flex;
  flex-wrap: wrap;
}
.meharCommon-submenu-cls .Tools_tools__item__3hXAo{
  border: none;
  display: flex;
  margin-top:10px;
  width: 100px;
  justify-content: center;
}
.meharCommon-submenu-cls span.Tools_tools__itemText__Vyhd- {
  display: flex;
  flex-direction: column;
}
.meharCommon-submenu-cls span.Tools_tools__itemText__Vyhd- span {
  font-size: 12px;
}

.comman-cutcrop-cls button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor:pointer;

}

.comman-cutcrop-cls button img {
  margin-right: 10px;
  width: 43px;
  height: 43px;
}

.common-editor-wrapper {
  max-width: 230px;
  width: 100%;
  margin: 30px auto 0;
}
.comman-cutcrop-cls {
  margin-bottom: 20px;
  margin-top: 15px;
}
.common-editor-wrapper .FaceCropPopup_input__icon__3nzC0 {
   width: 43px;
  height: 43px;
}
.comman-cutcrop-cls button span,
.comman-cutcrop-cls button a {
  color: #000!important;
}
.custom-secondary {
  border-radius: 50px!important;
  background-color: #000!important;
}

.custom-secondary:hover {
    color: #fff!important;
    background-color: #4e545b!important;
    border-color: #4e545b!important;
    box-shadow: 0 5px 15px #0000000d, 0 4px 10px #5a616940!important;
}
.mehrDivCls {
  width: 100%!important;
}

.previewCommon-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}
.previewCommon-wrapper button {
  display: flex;
  align-items: center;
  height: 50px;
}
.previewCommon-wrapper button img {
  margin-bottom: 0!important;
  width: 12px!important;
  margin-right: 10px;
}
.previewCommon-wrapper .commonElementeBtn img {
  width: 20px!important;
}
.meharCommon-submenu-cls button img.emoji-svg-hover {
  display: none;
}
.meharCommon-submenu-cls button:hover img.emoji-svg-hover {
  display: inline;
}
.meharCommon-submenu-cls button:hover img.emoji-svg {
  display: none;
}
.meharCommon-submenu-cls button:hover {
  background: none;
}
.commonActiveclsEmojiCls img.emoji-svg{
  display: none!important;
}
.commonActiveclsEmojiCls img.emoji-svg-hover{
  display: inline!important;
}

.commonActiveclsShapesCls img.emoji-svg{
  display: none!important;
}
.commonActiveclsShapesCls img.emoji-svg-hover{
  display: inline!important;
}

.commonActiveclsElementsListCls img.emoji-svg{
  display: none!important;
}
.commonActiveclsElementsListCls img.emoji-svg-hover{
  display: inline!important;
}
.removed-background-images-heading {
  font-size: 13px;
  line-height: 18px;
}
.removed-background-images-wrapper img {
  border: 1px solid #d9d9d9;
  object-fit: cover;
  width:50px;
  height:50px;
  margin: 7px 2px;
  cursor: pointer;
}
.newcustomcls span {
  font-size: .875rem;
  line-height: 24px;
  color:#5a6169;
}


.newcustomcls {
  margin-bottom: 0!important;
}
  
.newcustomcls{
  border-bottom: none !important;
}
.upload_cstm--active {
  background-color: #EAF9FF;
}
.upload_cstm--non-active {
  background-color: #fff;
}
.newcustomcls button{
  background-color: #EAF9FF;
}
.neweditorbtnCls .rcs-custom-scroll {
  height: auto!important;
}
.Mehrbtnwrapper button {
  max-width: 56px;
  margin: 0 10px!important;
  overflow: initial!important;
  display:inline-flex;
}
.Mehrbtnwrapper button span {
  overflow: visible;
  width:100%;
}
.Mehrbtnwrapper button span span {
  font-size: 14px;
  color: #333;
  font-weight: 400;
  font-family: Arial;
  line-height: 18px;
  width:auto;
}

.Mehrbtnwrapper{ 
  width: 100%;
  // background-color: #fff;
}
.cst-num-input {
  width:50px;
  height: 27px!important;
  
}
input.cst-num-input::-webkit-inner-spin-button{
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}
input.cst-num-input {
  -moz-appearance: textfield;
}

button.cstmChangeimagebtn {
  padding: .75rem 1.25rem!important;
  font-size: .875rem!important;
  font-weight: 300!important;
}

button.aligntextcstmclass span {
  margin-left: 30px;
}

button.aligntextcstmclass {
  font-size: .875rem;
  line-height: 1.125;
  font-weight: 300;
  font-family: Poppins,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
}
body.InnnerPopupOpen {
  overflow: hidden!important;
}
.custome_elementbtn_wrapper {
  display: inherit;
  max-width: 456px;

}
.cstm_zoombtn_wrapper {
  display: flex;
}
.custome_elementbtn_wrapper .element_buttons button{
  box-shadow: none!important;
}
.custome_elementbtn_wrapper .element_buttons{
  max-width: 400px;
  margin: 30px auto 0;
}
// .cstm_zoombtn_wrapper .plusMinusBtn{
//   position: relative;
//   margin-bottom: 20px;
// }


/* 07-july-2022 */
.designer-container .designer-tools-uploader {
  background-color: #f3f3f3;
}
.ToolsViews_imgMenu__1Y-XB, .ToolsViews_textMenu__1H1Id {
  background-color: #fff;
}
.custome_elementbtn_wrapper button {
  max-width: 48px;
  margin-right: 17px;
  border-radius:0;
}
// .neweditorbtnCls{
//   background-color: #fff;
// }
.cstm_zoombtn_wrapper button span {
  font-size: 12px;
  font-weight: 400;
  color: #333;
  font-family: 'Montserrat';
}
.cstm_zoombtn_wrapper .ToolsViews_active__HC2pl, 
.cstm_zoombtn_wrapper .ToolsViews_activeColor__3azXm,
.cs-scroll1 .cust_sub_menu .ToolsViews_active__HC2pl.cstm_button_align_wrap {
  padding-bottom: 0!important;
  margin-bottom: 0!important;
}
.cstm_zoombtn_wrapper .plusMinusBtn{
  position:relative;
  margin-bottom: 15px;
  width:100%;
  left:0;
}
.custome_elementbtn_wrapper{
  padding-top: 5px;
}
.textAreaSpacing textarea {
    margin: 0;
}
.sketch-picker {
  width: 100%!important;
  max-width: 400px !important;
  box-shadow: none!important;
  margin: 0 auto;
  border: 1px solid #d2d1d1;
}
.sketch-picker>div:first-child {
  padding-bottom:65%!important;
}
.bottom_menu_btn_layer1{
  white-space: nowrap;
}
.bottom_menu_btn_layer2{
  display: none;
}

// 311022  start here

.bottom_menu_btn_layer1 button {
  border-radius: 0;
  border: 0;
  background-color: #E8E8E8;
  padding: 12px 15px;
  display: inline-block;
}
.bottom_menu_btn_layer1 button:last-of-type {
  margin-right: 0;
}
.cstm_main_menu_tabs .Tools_tools__itemTextExpand__1Vpgd {
  display: none;
}
.cstm_main_menu_tabs button:hover,
.cstm_main_menu_tabs button.Tools_tools__item--active__tjyrX {
  background-color: #fff;
}
.designer-content{
  margin-top: 0;
  padding-top: 14px;
  background-color: #f3f3f3;
}
.designer-content .Tools_tools__content--color__2qAzR,
.designer-content .ToolsViews_imgMenu__1Y-XB, 
.designer-content .ToolsViews_textMenu__1H1Id{
  background-color: #f3f3f3;
}
.bottom_menu_btn_layer1 { 
  overflow: auto;
  max-width: 700px;   
}
.cust-font-selector{
  max-height: 100%;
  overflow: inherit;
  margin-bottom: 20px;
}
.cust-font-selector .dropdownContentList{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.cust-font-selector .dropdownContentList .font{
  margin:10px;
  white-space: nowrap;
  padding: 0;
}
.submenu_back_arrow img {
  width: 15px!important;
  height: 15px;
}
.submenu_back_arrow {
  top: 50% !important;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 10px!important;
  height: 30px;
  right: unset !important;
  width: 30px!important;
  position: absolute !important;
  display: flex!important;
  align-items: center;
  justify-content: center;
  margin-right:5px;
}
.cust_sub_menu img {
  margin: 0;
}
.cust-font-selector-outer{
  width: 100%;
  padding-left: 20px;
  margin-bottom: 20px;
}
.cust-border-slider{
  background-color: #f3f3f3!important;
}
.designer-container {
  min-height: 600px;
}
.designer-tools-uploader.emoji_outer_wrapper,
.designer-tools-uploader.element_list_wrapper,
.designer-tools-uploader.cstm-shapes-wrapper {
  background-color: #f3f3f3;
}
.font-family-selector .active-font {
 white-space: nowrap;
}
.ToolsViews_textMenu_listItem__22QaP textarea {
   background-color: white;
}

#mobile_data_hide_new{
  display: none;
}
.border_bottom_remove{
  border-bottom: none;
}


/* 221209 */

.desktop_redesign_wrapper .customeDesignBlock .cstm_main_menu_tabs .bottom_menu_btn_wrapper {
  width: 100%;
}

.desktop_redesign_wrapper .customeDesignBlock .cstm_main_menu_tabs .bottom_menu_btn_wrapper button {min-width: 22%;}

// .desktop_redesign_wrapper .customeDesignBlock button.image-control-wrap {
//   max-width: initial!important;
//   margin: 0 30px!important;
// }
.desktop_redesign_wrapper .customeDesignBlock .cstm_main_menu_tabs .bottom_menu_btn_layer1 {
  margin: 0 auto;
}

/* 221209 */

.cstm_image_upload_heading .text-form-popup-close img {
  max-width: 42px;
}

// 201222


.color_Picker_responsive {
  // background-image: conic-gradient(red, magenta,blue,rgb(0, 237, 245), rgb(31, 235, 31), yellow,orange);
  height: 41px;
  width: 41px;
  flex: 0 0 41px;
  // margin: 0 3px;
  cursor:pointer;
  border:1px solid transparent;
  padding:3px;
}
.color_Picker_responsive.active{
  border: 1px solid #4c4c4c;
}

.color_Picker_responsive img {
  width: 100%;
  height: 100%;
  margin:0;
}

.special_color_btn_slide_with_colors::-webkit-scrollbar {
  display: none;
}

.special_color_btn_slide_with_colors.outline_text{
  margin-left: 25px;
}

.color_text{
    width: 33px;
    height: 33px;   
    flex: 0 0 33px;   
  }
.color_text_wrapper{
  border: 1px solid transparent;
  padding: 3px;
}
.color_text_wrapper.active{
  border: 1px solid #4c4c4c;
}
.desktop_color_slider {
  display: flex;
  align-items: center;
  justify-content:center;
  padding-bottom: 30px;
  flex-wrap: wrap;
}
.cust_sub_menu{
  padding-bottom:10px;
}
.desktop_outline_shadow_wrapper .color_selector_btn_wrapper .designer-right-panel-item_shadow {
  padding: 0;
  padding-top: 5px;
}
.desktop_outline_shadow_wrapper,
.desktop_outline_shadow_wrapper2 {
  display: flex;
  justify-content: center;
}
.desktop_outline_shadow_wrapper .desktop_color_slider,
.desktop_outline_shadow_wrapper2  .desktop_color_slider{
  justify-content: flex-start
}
.desktop_outline_shadow_wrapper2 .outline_selector_btn_wrapper .designer-right-panel-item {
  padding:0;
  padding-top: 3px;
}
.desktop_outline_shadow_wrapper2 .special_color_btn_slide_with_colors.outline_text{
  margin-left:10px;
}

.cstm_shape_color_slider.desktop {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // margin: 15px 0 25px;
  margin: 15px auto 25px;
  max-width: 470px;
}
.desktop_color_slider {
  margin: 0px auto 25px;
  max-width: 470px;
}
.desktop_outline_shadow_wrapper,
.desktop_outline_shadow_wrapper2 {
  max-width: 550px;
  margin: 0 auto;
}
.desktop_outline_shadow_wrapper2 .designer-right-panel-inputNumber {
  padding-left: 0px;
  margin-left: 0px;
}

.custome_elementbtn_wrapper {
  max-width: 100%;
  justify-content: center;
} 

.ToolsViews_active__HC2pl .ToolsViews_elRotationMenu__15VeU img, 
.ToolsViews_active__HC2pl .ToolsViews_elZoomMenu__1psMa img, 
.ToolsViews_activeColor__3azXm .ToolsViews_elRotationMenu__15VeU img, 
.ToolsViews_activeColor__3azXm .ToolsViews_elZoomMenu__1psMa img,
.ToolsViews_active__HC2pl .ToolsViews_elColorMenu__-7ofO img, 
.ToolsViews_active__HC2pl .ToolsViews_activeFontSize__3p6ZH img, 
.ToolsViews_activeColor__3azXm .ToolsViews_elColorMenu__-7ofO img, 
.ToolsViews_activeColor__3azXm .ToolsViews_activeFontSize__3p6ZH img{
  max-width: 30px;
}
.pencil_icon_new{
  max-width: 42px!important;
  padding-bottom: 5px;
}

textarea{
  border-radius: 0!important;
  border: none!important;
  border-color: #ADADAD!important;
  resize: unset;
}
textarea:focus,
textarea:visited,
textarea:active,
textarea.active,
input:focus,
input:visited,
input:active,
input.active{
  outline: none;
  border-width: 1px!important;
  border-color: #ADADAD!important;
}
textarea.active_element_textarea{
  border:2px solid #4c4c4c!important;
}

.image-control-wrap span,
.input_field_cust input,
.designer-right-panel-inputNumber input{
  border-radius: 0;
  border: 0;
}

.bottom_menu_btn_layer1 button span,
.bottom_menu_btn_layer2 button span,
.cust_font_change span,
.NewEditor-custom-button button span {
  font-weight: bold;
  font-family: $font-primary;
  font-size: 16px;
}

textarea,
.cust_sub_menu button span,
.image-control-wrap span,
.input_field_cust input,
.designer-right-panel-inputNumber input,
.Mehrbtnwrapper button span span,
.cstm_button_align_wrap button span{
  font-family: $font-primary!important;
  font-weight: 500!important;
}
.input_field_cust input,
.border_bottom_remove input,
.ToolsViews_elRotationMenu__15VeU span{
  text-align: center;
  padding: 5px!important;
  font-size: 15px;
  color: #000;
}
.cstm_textareaSpacing textarea{
  padding: 15px;
  font-size: 15px;
}
.cstm_textareaSpacing .ToolsViews_textMenu_listItem__22QaP{
  margin: 5px auto;
}

.straightline_meharMenu button:hover, 
.straightline_meharMenu button.Tools_tools__item--active__tjyrX {
  background-color: transparent;
}
.straightline_meharMenu button span {
  font-family: $font-primary!important;
  font-weight: 500 !important;
  font-size: 14px!important;
}
#StraightlinedEditorActiveSubMenu .meharCommon-submenu-cls button {
  max-width: initial !important;
  margin: 0 20px!important;
  padding: 0;
  width: auto!important;
}
.cstm-popup-content-inner .text-form-popup-content-buttons button, 
.cstm-imgCrop-content-inner .text-form-popup-content-buttons button {
  min-width: 132px;
}
.cstm-popup-content-inner .custom-secondary, 
.cstm-imgCrop-content-inner .custom-secondary {
  border-color: #92D7A8 !important;
  background-color: #92D7A8 !important;
}
.cstm-imgCrop-content-inner .text-form-popup-content-buttons button {
  background-color: #E8E8E8;
  font-weight: 700;
  border-color: #E8E8E8;
  color: #000;
}
.Mehrbtnwrapper button:hover {
  background: none;
}
.cstm_main_menu_tabs button.BackBtnMobileMehr.text_active {
  background-color: #E8E8E8;
}
.back_ahead_btn img {
  height: unset;
  width: 34px;
  margin-bottom:5px;
}
#AdminViewDesign .text-form-popup-content-inner textarea{
  border: 2px solid rgba(0, 0, 0, 0.5) !important;
  border-radius: 10px !important;
}
.text-form-popup-content-buttons .new_designer_btn_back_grey.btn-outline-secondary{
  border: 0;
    background-color: #efeded;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    color: black;
    min-width: 144px;
}
.cstm-popup-content-inner .text-form-popup-content-buttons button {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: black;
  min-width: 144px;
}
.cstm-popup-content-inner .newcustomcls button {
  background-color: #d3d3d3;
  border-color: #d3d3d3;
  margin: 20px 0 40px 0;
 
}
.cstm-popup-content-inner .newcustomcls button.custombtncls span {
  font-family: "Montserrat", sans-serif;
  font-weight: 700 !important;
  color: black;
}
.image_coloum_two button.btn-outline-secondary, .cstm_design_uploader button.btn-outline-secondary {
  border-color: #92D7A8;
  background-color: #92D7A8;
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
}

//RS-2547 
.main_Image_wrapper img{ 
    max-width: 400px;
    max-height: 370px;
    object-fit: contain;
    width: 100%;
    margin: 0 auto;
    display: block;
}
.two_facecut_Images_wrapper img {
  max-width: 70px;
  width: 100%;
  max-height: 70px;
  object-fit: contain;
  border-radius: 100px;
  padding:5px;
  cursor: pointer;
}
.two_facecut_Images_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.two_facecut_Images_wrapper img.active{
  border: 4px solid green;
}
.facecut_button_wrapper .custom-secondary {
  border-color: #92D7A8!important;
  background-color: #92D7A8!important;
}
.facecut_button_wrapper button{
  min-width: 132px;
  background-color: #E8E8E8;
  font-weight: 700;
  border-color: #E8E8E8;
  color: #000;
}
.two_facecut_Image p {
  margin: 0;
  font-size: 12px;
  line-height: 18px;
  color:#000;
}
.two_facecut_Image {
  max-width: 100px;
  margin: 0px  7px;
  text-align: center;
}
.cutout-pro-cartoon-type{
  display: block;
}
.cartoon-type-custm1{
  padding-bottom: 5px;
}
.cartoon-type-custm2{
  padding-top: 5px;
}
.loading-cust-wait{
  background-color: #00000094;
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.loading-cust-wait .loading-text span{
  color: white;
  font-size: 20px;
  font-weight: 500;
}
.btn-div-wrapper .image-control-wrap {
  margin: 0 15px!important;
  max-width: 76px;
}

.designer-admin-mode {
  flex-wrap: wrap;
}
.preview-wrap {
  max-width: 100%;
  margin: 0 auto;
  width: 32%px;
  height: 61%px;
  .preview-front,.preview-back{
    width: 100%;
    position: relative;
    img{
      width: 100%;
    }
    .canvas_front_back{
      position: absolute; 
      left: 101px;
      top: 144px;
      width: 260px;
      height: 260px;
      border: 1px dashed #000;
      overflow: hidden;
      &>div{
        position: absolute !important;
        right: 0;
        top: 0;
      }
    }
  }
  .preview-front{
    .canvas_front_back{
      left: 186px;
      &>div{
        right: unset;
        left: 0;
      }
    }
  }
  .preview-btn {
    margin-top: 20px;
    justify-content: center;
    display: flex;
    gap: 10px;
    position: relative;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    span{
      width: 50px;
      cursor: pointer;
      img{
        width: 100%;
      }
    }
  }
}

//RS-2547 End

// responsive start here

@media (min-width: 617px){
  .meharCommon-submenu-cls span.Tools_tools__itemText__Vyhd- img{
    width: 56px;
  }
  .Mehrbtnwrapper{
    margin:0px;
  }
  .custome_elementbtn_wrapper button {
    max-width: 56px;
  }

  // 11-11-22
  .zoom_btn_desktop_cust{
    width: 56px !important;
  }
  // 11-11-22 End

}

// max-width start here***************************

@media (max-width:1279px){
  .NewEditor-custom-button{
    position: fixed;
    bottom: 15PX;
    left: 50%;
    transform: translateX(-50%);
  }
  .neweditorbtnCls{
    padding-bottom:190px;
  }

}

@media (max-width:1199px) {

      .text-form-popup-content-inner .text-form-popup-content-buttons {
        // position: absolute;
        // bottom: 0;
        // display: flex;
        // justify-content: center;
        // left: 0;
        // width: 100%;
        background: #fff;
        // padding: 15px 0;
      }    
    .imgUploaderScrollCls .customscrollbar-1,
    .imgUploaderScrollCls .scrollbar-1 {
      height: calc(100vh - 105px)!important;
    }
    .Dropzone {
      height: auto;
      background: unset;
        .dropzone-info {
          display: none;
      }
    }
}

@media (max-width:1024px) and (orientation:portrait) {
    .neweditorWrapper{
      height: calc((100vh - 100vw) - 44px )!important;
      flex-grow: 1;
    }
    .neweditorWrapper .neweditorbtnCls{
        display: flex;
        height: 100%;
    }
    .neweditorWrapper .cs-desk .top-scroll{
      display: flex;
      height: 100%;
    }
    .neweditorWrapper .neweditorbtnCls .rcs-custom-scroll{
      height: 100%!important;
    }
    .neweditorWrapper .mehrDivClsEmojiETC{
      padding-bottom: 90px;

    }
    .neweditorWrapper .cs-desk.top-scroll {
      height: 100%;
  }
  .neweditorWrapperDesigner{
    display: flex;
    flex-direction: column;
  }
  .neweditorWrapperDesigner .designer-content, .neweditorWrapperDesigner .designer-block{
    flex-grow:1;
  }

  }
 
  @media (max-width:991px){
      .firebox-genius-heading {
        font-size: 26px;
        line-height: 36px;
        margin-bottom: 30px;
    }
    .previewCommon-wrapper button {
      min-width: 239px;
   }

  }
  @media (min-width: 769px) {
    .designer-tools-backgrounds-list-colors .btn {
        box-shadow: 0px 0px 12px -4px #ccc;
    }
    }

  @media (max-width:767px){
      .firebox-genius-heading span {
        display: block;
    }
    .firebox-genius-heading.firebox-genius-heading-desktop {
      display: none;
    }
    .firebox-genius-heading.firebox-genius-heading-mobile {
      display: block;
      width:100%;
    }
    .firebox-genius-heading {
      margin-bottom: 20px;
      font-size: 21px;
      line-height: 31px;
    }  
    
  }

  @media (max-width:616px){

    .Mehrbtnwrapper button {
      max-width: 48px;
      margin: 0 5px!important;
    }
    .Mehrbtnwrapper button span span {
      font-size: 12px;
    }
    .Mehrbtnwrapper{
      padding:10px 0;
    }
    .custome_elementbtn_wrapper {
      max-width: 410px;
    } 

    //271022

    .cstm_main_menu_tabs {
      position: absolute;
      bottom: 0px;
      background-color: inherit;
    }
    .bottom_menu_btn_layer1 button {    
      min-width: calc(33.333vw - 3px);
    }
    .bottom_menu_btn_layer1{
      width: 100vw;
      display: block;
      padding-bottom: 57px;
    }
   
        /* Hide scrollbar for Chrome, Safari and Opera */
        .bottom_menu_btn_layer1::-webkit-scrollbar {
          display: none;
        }
    
        /* Hide scrollbar for IE, Edge and Firefox */
        .bottom_menu_btn_layer1{
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
        }
    

    .customeDesignBlock .Tools_tools__1NvJb,
    .designer-block {
      flex-grow: 1;
    }
    .designer-content {
      min-height: calc(100% - 47px);
    }
    .neweditorbtnCls {
      background-color: inherit;
    }
    .cs-scroll1 .rcs-outer-container .rcs-inner-container > div > div[class^="ToolsViews_textMenu"],
    .Mehrbtnwrapper,
    .ToolsViews_imgMenu__1Y-XB, 
    .ToolsViews_textMenu__1H1Id {
      background: transparent;
    }
  
    .bottom_menu_btn_wrapper{
      display: flex;
      flex-direction: column;
      width: 100%;
    }
   
    .bottom_menu_btn_layer2 {
      display: flex;
      justify-content: space-around;
      align-items:center;
      background: #E8E8E8;
      padding: 10px;
      position: fixed;
      bottom: 0;
      z-index: 99;
      width: 100%;
    }
    .bottom_menu_btn_layer2 button {
      border-color: #fff;
      background-color: #FFF;
      box-shadow: none;
      border-radius: 50px;
      padding: 10px 25px;
      font-weight: 400;
  }
    .bottom_menu_btn_layer2 .cst-green {
     border-color: #92D7A8;
      background-color: #92D7A8;
      padding: 10px 50px;
      color: #000;
    } 
    .bottom_menu_btn_layer2 .cst-green:active,
    .bottom_menu_btn_layer2 .cst-green:hover    {
      border-color: #92D7A8!important;
      background-color: #92D7A8!important;
      box-shadow: none;
  }

    .bottom_menu_btn_layer1 button span,
    .bottom_menu_btn_layer2 button span {
        font-size: 15px;
    }

  .designer-tools-uploader.cust-border-slider {
      padding: 0;
  }
  .cust-border-slider .designer-tools-backgrounds-list {
      display: flex;
      overflow: auto;
      position: absolute;
      bottom: 95px;
      width: 100%;
      background: #E8E8E8;
      left: 0;
      padding: 0;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }
  .cust-border-slider .designer-tools-backgrounds-list::-webkit-scrollbar {
    display: none;
  }
  .cust-border-slider .designer-tools-backgrounds-list img {
    background: #fff;
    margin-right: 0;
  }
  .cust-border-slider .designer-tools-backgrounds-list img:last-child {
    margin-right: 5px;
  }
  .cust-border-slider .designer-tools-backgrounds-list>div:first-child {
    display: none;
  }
  .ToolsViews_fontAlightText__2nKoI.cstm_button_align_wrap {
    width: auto!important;
    margin-right: 5px;
  }
  .rcs-inner-container .cust_sub_menu {
    flex-wrap: nowrap!important;
    position: absolute;
    bottom: 100px;
    left: 0;
    right: 0;
    margin: 0;
    background-color: #fff!important;
    max-width: 100%!important;    
    padding-left: 5px!important;
    }

    // hide scrollbar 

    .rcs-inner-container .cust_sub_menu::-webkit-scrollbar,
    .cust-font-selector .dropdownContentList::-webkit-scrollbar {
      display: none;
    }
    .rcs-inner-container .cust_sub_menu,
    .cust-font-selector .dropdownContentList{
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }        
    .rcs-inner-container .ToolsViews_textMenu__1H1Id.cs-text-width.cust_sub_menu {
      overflow: auto!important;
      align-items: flex-start;
    }
    .cstm_button_align_wrap button {
      flex-direction: column;
    }
    .cust_sub_menu button {
      margin-bottom: 0;
      margin-top: -10px;
    }
    .cstm_textareaSpacing{
      max-height: 220px  ;
      overflow: scroll!important;
    }
    .cust_sub_menu button img{
      margin-bottom: 0!important;
    }
    .ToolsViews_fontAlightText__2nKoI button img {
      margin: 0px 2px 0;
      margin-bottom: 5px;
      width: 45px;      
    }
    .cust-font-selector .dropdownContentList {
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
    }
    .font_size_mobile_cust img,
    .letter_spacing_mobile_cust img {
      max-width: 40px;
      margin: 0 12px;
      max-height: 40px;
    }
    .ToolsViews_textMenu__1H1Id img{
      height: 48px;
      -webkit-touch-callout: none!important;
    }
    .ToolsViews_textMenu__1H1Id .special_color_btn_slide_with_colors .color_Picker_responsive img{
      height: unset;
    }

    .font_size_mobile_cust .cst-num-input,
    .letter_spacing_mobile_cust .cst-num-input{
      height: auto!important;
      border-radius: 0;
      border: 1px solid #000;
      font-family: $font-primary;
      font-weight: 500;
      font-size: 15px;
      text-align: center;
      color: #000;
    }
    
    .image-control-wrapper {
      position: absolute;
      bottom: 100px;
      display: inline-block;
      overflow: auto;
      white-space: nowrap;
      width: 100vw;
      max-width: 100% !important;
      left: 0;
      right: 0;
      padding: 0 9px 7px!important;
      background-color: #fff !important;
    }
    .image-control-wrapper button{
      width: calc(25vw - 25px);
      max-width: 100%!important;
      margin-bottom: 0;
      font-size: 12px;
    }
    .image-control-wrapper button span{
      white-space: normal;
    }
    
    .image-control-wrapper button img {
      margin-bottom: 0;
    }

    .cust_menu_submenu_mobile {
      display: flex;
      align-items: center;
    }
      
    .cust_menu_submenu_mobile .rotate_btn_mobile_wrap,
    .cust_menu_submenu_mobile .zoom_btn_mobile_wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 12px 0;
    }
    
    .cust_menu_submenu_mobile .rotate_btn_mobile_wrap img,
    .cust_menu_submenu_mobile .zoom_btn_mobile_wrap img {
      max-width: 40px;
      margin: 0 12px;
      -webkit-touch-callout: none!important;
    }
  
    .cust_menu_submenu_mobile .zoom_btn_mobile_wrap img {
      margin: 0 5px;
    }
    
    .cust_menu_submenu_mobile .rotate_btn_mobile_wrap span.cs-number-input {
      border-radius: 0;
      border: 1px solid #000;
      margin-bottom: 0;
      height: 40px!important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 2px!important;
      font-family: $font-primary;
      font-weight: 500;
      font-size: 15px;
    } 
    
    // RS-2285
    .Mehrbtnwrapper {
      position: absolute;
      bottom: 100px;
      background-color: #fff;
      padding-top: 0;
      overflow: auto;
    }
    .Mehrbtnwrapper button {
      width: calc(25vw - 5px);
      max-width: 100%;
      min-width: unset;
    }
   .emoji_inner_wrapper {
      width: 1050px;
      padding-left:0px;
    }  
    .designer-tools-uploader.emoji_outer_wrapper{
        overflow: auto;
        background: #fff;
        margin: 0;
        position: absolute;
        bottom: 100px;
        left: 0;
        height: 120px;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    .designer-tools-uploader.emoji_outer_wrapper::-webkit-scrollbar{
      display: none;
    }
    .cstm-shapes-wrapper .custome_elementbtn_wrapper {
      position: absolute;
      background: #fff;
      bottom: 100px;
      left: 0;
      width: 100%;
      max-width: 100%;
      padding: 10px;
  }
  .cstm-shapes-wrapper .custome_elementbtn_wrapper  .element_buttons{
    text-align: center;
    margin-top:0;
  }
  .Mehrbtnwrapper button img{
    margin-bottom:0!important;
  }
  .element_list_wrapper{
    background: #fff!important; 
    position: relative;
    margin-bottom: -90px;
  }
  .element_list_wrapper .rcs-custom-scroll{
    height: auto!important;
  }
    .element_list_wrapper .ToolsViews_dropdown__111vP{
      background: #fff!important;
    }

  .cstm-shapes-wrapper {
      padding: 0!important;
  }
  .neweditorbtnCls{
    padding:0;
    padding-bottom:190px;
    margin-top: auto;
    flex-grow: 1;
  }
  .designer-content{
    padding-top: 0px;
  }
  .cust_back_elementslist{
    padding-left:20px;
  }

  .cust_back_elementslist .submenu_back_arrow {
      top: 15px!important;
      -webkit-transform: unset;
      transform: unset;
      left:20px!important;
  }

  .emoji-main-wrapper .submenu_back_arrow {
    top: 50px!important;
    transform: unset;
    z-index:1;
  }

  .emoji-main-wrapper::-webkit-scrollbar{
    display: none;
  }

  .emoji-main-wrapper {
    padding-left: 0px;
      background: #fff;
      left: 0;
      bottom: 100px;
      overflow: auto;
      height: 120px;
      position: absolute;
      width: 100%;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      
  }
  .emoji-main-wrapper .designer-tools-uploader.emoji_outer_wrapper {
    bottom: 0;
    margin-left: 40px;
    padding-left: 0;
  }

  .customeDesignBlock .Tools_tools__1NvJb{ 
    display: flex;
  }



  // RS-2285 End 

  .cross_btn_mobile_cust {
    text-align: right!important;
    display: block!important;
    padding: 15px 15px 0 0;
  }
  .shadow_text_section_mobile_wrap,
  .outline_text_section_mobile_wrap {
    display: flex;
    align-items: center;
  }
  .shadow_text_section_mobile_wrap .designer-right-panel-item_shadow,
  .outline_text_section_mobile_wrap .designer-right-panel-item__FontStroke {
    width: 66px;
    border: none;
  }
  .shadow_text_section_mobile_wrap .designer-right-panel-item,
  .outline_text_section_mobile_wrap .cstm_color_item_wrapper {
    margin-right: 66px;
  }
  .design_frame_sec{
    bottom:101px!important;
  }  

  .color_selector_btn_wrapper .designer-right-panel-item_shadow {
    margin-left: 40px;
    margin-top: 7px;
    display: flex;
    align-items: center;
    padding: 0;
  }

  .color_selector_btn_wrapper input[type="checkbox"], 
  .color_selector_btn_wrapper input[type="radio"] {
    display: none;
  }
  .outline_selector_btn_wrapper .designer-right-panel-item{
      margin-top:0;
  }
  .outline_selector_btn_wrapper  .designer-right-panel-inputNumber {
    padding-left: 0;
    margin-bottom: 0;
    margin-left: 55px;
  }
    
  .special_color_btn_slide_with_colors{
    margin-left: 35px;
    overflow: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none /* Firefox */
  }
  .special_color_btn_slide_with_colors::-webkit-scrollbar {
    display: none;
  }


// 061222

  .cstm_image_upload_heading{
    background-color: #ffffff;
    padding: 20px;
    justify-content: center;
  }
  .cstm_image_upload_heading .text-form-popup-close{
    position:absolute;
    top:10px;
    right:32px;
  }

  .cstm_design_uploader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%!important;
  }


  .content_wrap_upload_btn {
    text-align: center;
  }
  .content_wrap_upload_btn svg {
    width: 25px;
    height: 25px;
    display: block;
    margin-left: auto;
    margin-bottom: 5px;
    fill: #0275d8;
  }
  .content_wrap_upload_btn span {
      display: block;
  }

  .image_coloum_two .withoutPopupUploading img {
      margin: 20px;
      border: 1px solid #000000;
      max-width: calc(50% - 40px)!important;
      border-radius:0;
  }
  .cstm_design_uploader button.btn-outline-secondary,
  .text_area_info_btn_wrapper button.btn-outline-secondary{
    border-color: #92D7A8;
    background-color: #92D7A8;
    font-weight: 700;
    font-size: 15px;
    line-height: 25px;
  }
  .cstm_image_upload_heading .text-form-popup-content-title span {
    font-size: 20px;
  }

  .cstm_shape_color_slider{
    display: flex;
  }
  .custome_elementbtn_wrapper .cstm_zoombtn_wrapper{
    display:none;
  }

  .cstm-popup-content-inner {
    background: #F3F3F3;
  }
  .cstm-imgCrop-content-inner {
    background: #F3F3F3;
    display: flex;
    flex-direction: column;
  }
  .cstm-popup-content-inner .text-form-popup-content-buttons,
  .cstm-imgCrop-content-inner .text-form-popup-content-buttons {
    padding: 25px 10px;
    margin: 40px -10px -10px;
  }
  .cstm-popup-content-inner .text-form-popup-content-buttons button,
  .cstm-imgCrop-content-inner .text-form-popup-content-buttons button{
    min-width: 132px;
  }
  .cstm-popup-content-inner .custom-secondary,
  .cstm-imgCrop-content-inner .custom-secondary{
    border-color: #92D7A8!important;
    background-color: #92D7A8!important;
  }
  .cstm-popup-content-inner .text-form-popup-content-title__wrap img {
      max-width: unset;
      width: 30px;
      height: 30px;
  }
  .cstm-popup-content-inner .text-form-popup-content-title__wrap .text-form-popup-close {
    max-width: unset;  
  }
  .cstm-popup-content-inner .text-form-popup-content-title__wrap {
    align-items: center;
  }
  .cstm-popup-content-inner .text-form-popup-content-title__wrap .text-form-popup-content-title {
    margin-bottom:0;
  }
  .cstm-popup-content-inner .newcustomcls button {
      background-color: #d3d3d3;
      border-color: #d3d3d3;
      margin: 20px 0 40px 0;
  }
  .cstm-popup-content-inner .newcustomcls button span {
    font-weight: 900;
    color:#222;
  }
  .cstm-popup-content-inner .cstm_crop__wrap_properties .input_slider img {
    width: 75px;
    height: 75px;
  }
  .cstm-popup-content-inner .cstm_crop__wrap_properties .icon_input{
    align-items: center;
    margin: 0 auto;
  }
  .cstm-popup-content-inner .cstm_crop__wrap_properties .input_slider {
    min-width: 200px;   
  }
  .canvas_imgCrop_wrapper{
    flex-grow: 1;
  }
  .sketch-picker {
      margin:40px auto 0;
  }
  .special_color_btn_slide_with_colors.shadow{
    margin-left:0;
  }
  .cust-font-selector-outer,
  .cust-font-selector {
    margin-bottom: 0;
}
.shape-color-picker-wrapper {
    margin-top: 60px;
}
.cust_image_sub_menu {
  padding-top: 10px;
  padding-right: 10px;
}
.color-picker-for-hinter{
  margin-top: 10px;
}
.color-picker-for-hinter .sketch-picker {
  margin: 20px auto 0;
}
.design_frame_sec img {
  max-height: 60px;
}
.NewEditor-custom-button-2 {
  z-index: 9;
  background: #e8e8e8;
  width: 100%;
  text-align: center;
  padding: 10px 5px;
  margin-bottom: -15px;
  margin-top:0;
}

#StraightlinedEditorActive {
  padding-bottom: 155px;
}

#StraightlinedEditorActive .cust_sub_menu {
  bottom: 67px;
}
#StraightlinedEditorActive .emoji-main-wrapper {
  bottom: 67px;
}
#StraightlinedEditorActiveSubMenu {
  bottom: 67px;
  background-color: #e8e8e8!important;
}

#StraightlinedEditorActive .cstm-shapes-wrapper .custome_elementbtn_wrapper {
  bottom: 67px;
  padding: 25px;
}

// 2361 center-sec start

.cstm_design_uploader .uploader-form-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cstm_design_uploader .Dropzone .dropzone-upload-button-faceUpload .btn-outline-secondary {
  margin: 0px auto;
}
.cstm_design_uploader .Dropzone {
  margin-top: 0;
}

// 2361 center-sec  end

.faceCropMultifaceWrap {
  padding: 10px!important;
  height: calc(100vh + 30px)!important;
}

.faceCropMultifaceWrap .rcs-custom-scroll {
  padding: 0;
}

.faceCropMultifaceWrap .designer-tools-uploader {
  padding: 0;
}
#StraightlinedEditorActive .mehrDivClsEmojiETC{
  padding-bottom: 0;
}

#StraightlinedEditorContainer{
  height:auto;
  min-height: 100vh;
}
#StraightlinedEditorContainer .neweditorWrapper {
  height: auto!important;
}
#StraightlinedEditorContainer .neweditorWrapper .neweditorbtnCls .rcs-custom-scroll {
   height: auto!important;
}
#StraightlinedEditorActiveSubMenu .meharCommon-submenu-cls{
  flex-wrap: nowrap!important;
  overflow: auto!important;
  white-space: normal;
  width: 100vw;
  max-width: 100% !important; 
}
#StraightlinedEditorActiveSubMenu .meharCommon-submenu-cls button {
  margin-top: 0;
  min-width: calc(25vw - 15px)!important;
  margin: 0 7.5px!important;
  padding-bottom: 5px;
}
#StraightlinedEditorActiveSubMenu .meharCommon-submenu-cls button span{
 font-size: 12px!important;
}
.input_field_cust input, .designer-right-panel-inputNumber input {
  border: 1px solid #000;
}
#StraightlinedEditorActive .element_list_wrapper {
  margin-bottom: 0;
}
#StraightlinedEditorContainer .Mehrbtnwrapper {
  display: none;
}
#StraightlinedEditorActiveSubMenu .commonActiveclsElementsListCls{
  background-color: #fff!important;
  border-radius: 0!important;
}
// .image_coloum_two{
//   height: 280px!important;
// }
// .imgUploaderScrollCls .image_coloum_two{
//   height: 100%!important;
// }
.MehrImageWrapper {
  padding-bottom: 120px;
}
.cstm_main_menu_tabs button:hover{
  background-color: #e8e8e8;
}
.cstm_main_menu_tabs button.Tools_tools__item--active__tjyrX{
  background-color: #fff;
}
// .cust_sub_menu button span {
//   white-space: nowrap;
// }
.cust_sub_menu button {
  max-width: 74px;
}
.back_ahead_btn img {
    height: unset;
    width: 28px;
    margin-bottom:0;
}
.text_area_info_btn_wrapper{
  text-align: center;
  text-align: center;
    max-width: 254px;
  width: 100%;
  margin: 20px auto;
}
.text_area_info_btn_wrapper .cstm_upload_btn_outer {
  margin-bottom: 30px;
}
.textArea_popup_cust .text-form-popup-content-inner{
  background-color: #f3f3f3;
}
.textArea_popup_cust .cstm_upload_btn_outer .content_wrap_upload_btn{
  position: relative;
}
.textArea_popup_cust .cstm_upload_btn_outer .content_wrap_upload_btn svg{
  position: absolute;
  right: -23px;
  top: -20px;
}
.textArea_popup_cust .textarea_content_wrapper{
  // max-height: 350px;
  max-height: calc(100vh - 285px  );
}
.textArea_popup_cust .textarea_content_wrapper .cstm_textareaSpacing{
  max-height: 100%;
}
.textArea_popup_cust .text-form-popup-content-inner{
  display: flex;
    flex-direction: column;
}
.textArea_popup_cust .text-form-popup-content-inner .text_area_info_btn_wrapper{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.add_textarea_wrapper{
  display: block!important;
  width: 100%!important;
  text-align: center!important;
}
.font-family-selector div{
  margin-top: 5px!important;
}
.textArea_popup_cust .cstm_image_upload_heading{
  background-color: #f3f3f3;
}
.cstm-popup-content-inner .new_design_width_wrapper{
  max-width: 250px;
}
.back-form-content-custm .text-form-popup-back img{
  width: 28px;
  height: unset;
}
.special_color_btn_slide_with_colors.remove_margin_mobile{
  margin-left: 10px;
  margin-right: 10px;
}
.cust-font-selector .dropdownContentList {
  padding-left: 0;
}
.cust-font-selector-outer {
  padding-left: 35px;
}
.color_Picker_responsive {
  margin: 0 3px;
}


}

  @media (max-width:575px){
    //   .CommonMainWrapperCls .designer-canvas #canvas {
    //     height: 300px!important;
    // }
    .meharCommon-submenu-cls span.Tools_tools__itemText__Vyhd- img {
      width: 48px;
      }
      .meharCommon-submenu-cls span.Tools_tools__itemText__Vyhd- span {
        font-size: 9px;
    }
    .meharCommon-submenu-cls .Tools_tools__item__3hXAo {
      width: 60px;
      margin-right: 0;
      padding: 4px;
    }
    .NewEditor-custom-button button {
      padding: 13px;
      font-size: 15px;  
    } 
  .custome_elementbtn_wrapper button {
    max-width: 48px;
    margin-bottom: 0;
    max-height: unset;
  }
  .custome_elementbtn_wrapper {
    display: inherit;
    max-width: 260px;
    margin: 0 auto;
  }
  .custome_elementbtn_wrapper .element_buttons button{
    max-width:38px;
  }
  .cstm_zoombtn_wrapper button span {
    font-size: 9px;
  }
  .sketch-picker {
    max-width: 280px!important;
  }
  .sketch-picker > div:first-child {
    padding-bottom: 75% !important;
  }
  .photoshop-picker {
    width: 100%!important;
  }
  .photoshop-picker>div {
    display: unset!important;
}
  .photoshop-picker>div.flexbox-fix>div:first-child {
    width: 82% !important;
    float: right; 
    margin-right: 10px;
  }
  #canvas_wrap_resp canvas {
      width: 100%!important;
      height: 100%!important;
  }
  .text_align_menu_submenu,
  .font_size_mobile_cust,
  .letter_spacing_mobile_cust,
  .outline_text_mobile_cust,
  .shadow_text_mobile_cust{
    margin-left: auto;
    margin-right: auto;
  }
  // .cs-text-width.cust_sub_menu{
  //   justify-content: center!important;
  // }
  .image-control-wrapper button{
    width: calc(25vw - 15px)!important;
  }
  #StraightlinedEditorActive .scrollbar-10{
    height: 400px!important;
  }


    
  }


  @media (max-width:374px){
      .comman-cutcrop-cls button img {
        width: 35px;
        height: 35px;
    }
    .common-editor-wrapper .FaceCropPopup_input__icon__3nzC0 {
      width: 35px;
     height: 35px;
   }

    .common-editor-wrapper {
        max-width: 200px;
    }
    .FaceCropPopup_input__btn__ek-83{
      border-bottom: none !important;
    }
    .imgUploaderScrollCls .withoutPopupUploading img{
      max-width: 68px;
    }
  }

  @supports (-webkit-touch-callout: none) {
    .rcs-inner-container .cust_sub_menu,
    .Mehrbtnwrapper,
    .image-control-wrapper,
    .emoji-main-wrapper{
      bottom:109px;
    }
    .element_list_wrapper {
      margin-bottom: -81px!important;
  }
  }



.CustomCanvasBackground canvas {
  background-image: linear-gradient(45deg, #ccc 25%, transparent 25%), 
  linear-gradient(-45deg, #ccc 25%, transparent 25%), 
  linear-gradient(45deg, transparent 75%, #ccc 75%),
  linear-gradient(-45deg, transparent 75%, #ccc 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

