@import "../../assets/scss/index";

.tools {
  //display: flex;
  align-self: flex-start;
  flex-direction: column;
  //position: absolute;
  z-index: 2;
  color: $primary;
  width: 100vw;
  height: 257px;
  @include media("<wide") {
    //margin-top: 21px;
    bottom: 0;
    left: auto;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    background-color: #F3F3F3;
    transform: translateX(0);
    overflow: hidden;
    height: 257px;
  }
  @include media(">wide") {
    max-width: 710px;
    height: 592px;
  }
  //@include media("<wide") {
  //  &:before {
  //    content: "";
  //    background-color: $white;
  //    display: block;
  //    position: absolute;
  //    z-index: 0;
  //    top: 0;
  //    height: 85px;
  //    left: 0;
  //    width: 100vw;
  //
  //  }
  //
  //}

  &--active {

  }

  &--menu {
    &--elements {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      align-items: flex-start;

      .tools__item {
        @include media("<wide") {
          margin: 0 10px;
        }
      }

    }

    &--btn {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      background-color: $white;
      @include media("<wide") {
        border-radius: 50%;
        box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, .3);
      }

      .tools__item {
        background-color: $white;
        @include media("<wide") {
          border-radius: 50%;
        }
      }
    }
  }

  &__itemText {
    overflow: hidden;
    text-overflow: ellipsis;

  }

  &__item {
    border: 1px solid #000000;
    border-radius: 100px;
    background-color: transparent;
    cursor: pointer;
    display: block;
    //width: 100%;
    padding: 8px 3px;
    transition: background-color .3s, color .3s;
    @include media("<wide") {
      &Icon {
        background-color: $white;
        border: 1px solid $btn-border-col;
        border-radius: 50%;
        box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, .3);
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0;

        &Main {
          background-color: transparent;
          border: none;
          //box-shadow: none;
          height: 40px;
          margin-top: 0;
        }
      }
    }



    &:first-child {
      margin-top: 0;
    }

    &Text {
      font-size: 15px;
      display: block;
      margin-top: 2px;
      @include media(">360px") {
        font-size: 18px;
      }
      @include media(">400px") {
        font-size: 20px;
      }
      @include media(">420px") {
        font-size: 23px;
      }
      @include media(">tablet") {
        font-size: 18px;
      }
    }
  }

  &__itemIcon {
    font-size: 20px;
    //display: block;
    //margin-bottom: 3px;
    fill: $primary;

    img, svg {
      display: none;

      &:first-child {
        margin: auto;
        display: block;
      }
    }
  }

  &__item {
    font-family: $font-primary;
    color: #333333;
    padding: 5px 7px;
    margin-right: 5px;
    @include media(">616px") {
      padding: 8px 15px;
      margin-right: 15px;
    }

    &.help {
      bottom: 0;
      position: absolute;
    }

    &:hover {
      //background-color: rgba($primary, .35);
      // fill: $white;
      background-color: #EAF9FF;
    }

    &--active {
      //@include media(">wide") {
      background-color: #EAF9FF;
      //}
      // color: $white;
    }
  }

  &__content {
    align-items: center;
    text-align: center;
    color: $white;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
    justify-content: center;
    z-index: 0;
    //max-width: 592px;
    width: 100%;
    @include media(">360px") {
      padding-top: 5px;
    }
    @include media(">400px") {
      padding-top: 5px;
    }
    @include media(">420px") {
      padding-top: 5px;
    }
    @include media(">tablet") {
      padding-top: 5px;
    }

    &--reverse {
      flex-direction: column-reverse;
    }

    &--color {
      background-color: $white;
    }

  }
}

.tools__more {
  display: none;
}

.tools__more_active {
  margin: 0 auto;
  display: flex;
  //top: 64px;
  width: 100vw;
  //position: absolute;
  background-color: $main-bg;
  justify-content: center;
  padding: 15px 0;
  //height: 100vh;
  //max-height: 37vh;
  align-items: flex-start;
  @include media(">616px") {
    margin: 20px auto;
    padding: 40px 59px;
    //top: 85px;
  }
}

.tool-item__collapseBtn {
  &_active {
    transform-origin: center;
    background-color: transparent;

    .tools__itemIcon {
      color: $primary;
      fill: $primary;
    }
  }

  &:hover {
    background-color: transparent;
    @include media("<wide") {
      background-color: $white;
    }
  }

  @include media(">wide") {
    display: none;
  }
  @include media("<wide") {
    background-color: $white;
  }
}

.tools__itemTextExpand {
  cursor: pointer;
  width: 100%;
  max-width: 25px;
  max-height: 25px;
  margin: 10px 0;
  transform: rotate(-180deg);
  border: 1px solid #000000;
  border-radius: 100px;
}

.tools__itemTextExpand--active {
  transform: rotate(270deg);
}

.tools__more .tools__item {
  border: none;
  padding: 0;
  margin-right: 21px;
  border-radius: 0;

  img {
    margin-bottom: 5px;
    width: 48px;
    @include media(">616px") {
      width: 56px;
    }
  }

}

.tools__more .tools__itemText {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.adminSubmenu {
  display: none;
  @include media(">wide"){
    display: flex;
    flex-direction: column;
    font-weight: 600;
    max-width: 350px;
  }
}

